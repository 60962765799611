<template>
  <div>
    <Header />
    <div class="faq">
      <!-- faq search section -->
      <section id="faq-search-filter">
        <div class="faq-top breadcrumbs d-flex align-items-center">
          <div
            class="container position-relative d-flex flex-column align-items-center"
          >
            <h2 class="">{{ $t("faq.Lets_answer") }}</h2>

            <!-- form -->
            <b-form class="faq-search-input">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="searchbar"
                  v-model="faqSearchQuery"
                  :placeholder="$t('faq.Search_faq')"
                />
              </b-input-group>
            </b-form>
            <!-- form -->
          </div>
        </div>
      </section>
      <!--/ faq search section -->
      <b-container>
        <!-- frequently asked questions tabs pills -->
        <section id="faq-tabs">
          <b-tabs
            vertical
            content-class="col-12 col-md-8 col-lg-9"
            pills
            nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
            nav-class="nav-left"
          >
            <!-- FAQ ALL tab -->
            <b-tab>
              <!-- title -->
              <template #title>
                <feather-icon icon="HelpCircleIcon" size="18" class="mr-1" />
                <span class="font-weight-bold">{{ $t("faq.faq") }}</span>
              </template>

              <faq-question-answer :options="faqGeneral" />
            </b-tab>
            <!--/ FAQ ALL tab -->

            <!-- FAQ Companies tab -->
            <b-tab v-if="faqCompanies.length > 0">
              <!-- title -->
              <template #title>
                <feather-icon
                  :icon="icons.faqCompanies"
                  size="18"
                  class="mr-1"
                />
                <span class="font-weight-bold">{{ $t("faq.Companies") }}</span>
              </template>

              <faq-question-answer :options="faqCompanies" />
            </b-tab>
            <!--/ FAQ Companies tab -->

            <!-- FAQ Freelancers tab -->
            <b-tab v-if="faqFreelancers.length > 0">
              <!-- title -->
              <template #title>
                <feather-icon
                  :icon="icons.faqFreelancers"
                  size="18"
                  class="mr-1"
                />
                <span class="font-weight-bold">{{
                  $t("faq.Freelancers")
                }}</span>
              </template>

              <faq-question-answer :options="faqFreelancers" />
            </b-tab>
            <!--/ FAQ Freelancers tab -->

            <!-- FAQ Investors tab -->
            <b-tab v-if="faqInvestors.length > 0">
              <!-- title -->
              <template #title>
                <feather-icon
                  :icon="icons.faqInvestors"
                  size="18"
                  class="mr-1"
                />
                <span class="font-weight-bold">{{ $t("faq.Investors") }}</span>
              </template>

              <faq-question-answer :options="faqInvestors" />
            </b-tab>
            <!--/ FAQ Investors tab -->

            <!-- FAQ Advisor tab -->
            <b-tab v-if="faqAdvisors.length > 0">
              <!-- title -->
              <template #title>
                <feather-icon
                  :icon="icons.faqAdvisors"
                  size="18"
                  class="mr-1"
                />
                <span class="font-weight-bold">{{ $t("faq.Advisors") }}</span>
              </template>

              <faq-question-answer :options="faqAdvisors" />
            </b-tab>
            <!--/ FAQ Advisor tab -->

            <!-- FAQ Brokers tab -->
            <b-tab v-if="faqBrokers.length > 0">
              <!-- title -->
              <template #title>
                <feather-icon :icon="icons.faqBrokers" size="18" class="mr-1" />
                <span class="font-weight-bold">{{ $t("faq.Brokers") }}</span>
              </template>

              <faq-question-answer :options="faqBrokers" />
            </b-tab>
            <!--/ FAQ Brokers tab -->

            <!-- FAQ Partners tab -->
            <b-tab v-if="faqPartners.length > 0">
              <!-- title -->
              <template #title>
                <feather-icon
                  :icon="icons.faqPartners"
                  size="18"
                  class="mr-1"
                />
                <span class="font-weight-bold">{{ $t("faq.Partners") }}</span>
              </template>

              <faq-question-answer :options="faqPartners" />
            </b-tab>
            <!--/ FAQ Partners tab -->

            <!-- FAQ Verification tab -->
            <b-tab v-if="faqVerification.length > 0">
              <!-- title -->
              <template #title>
                <feather-icon
                  :icon="icons.faqVerification"
                  size="18"
                  class="mr-1"
                />
                <span class="font-weight-bold">{{
                  $t("faq.Verification")
                }}</span>
              </template>

              <faq-question-answer :options="faqVerification" />
            </b-tab>
            <!--/ FAQ Verification tab -->

            <!-- FAQ Business_Loans tab -->
            <b-tab v-if="faqBusinessLoans.length > 0">
              <!-- title -->
              <template #title>
                <feather-icon
                  :icon="icons.faqBusinessLoans"
                  size="18"
                  class="mr-1"
                />
                <span class="font-weight-bold">{{
                  $t("faq.Business_Loans")
                }}</span>
              </template>

              <faq-question-answer :options="faqBusinessLoans" />
            </b-tab>
            <!--/ FAQ Business_Loans tab -->

            <!-- FAQ Ideas tab -->
            <b-tab v-if="faqIdeas.length > 0">
              <!-- title -->
              <template #title>
                <feather-icon :icon="icons.faqIdeas" size="18" class="mr-1" />
                <span class="font-weight-bold">{{ $t("faq.Ideas") }}</span>
              </template>

              <faq-question-answer :options="faqIdeas" />
            </b-tab>
            <!--/ FAQ Ideas tab -->

            <!-- FAQ Projects tab -->
            <b-tab v-if="faqProjects.length > 0">
              <!-- title -->
              <template #title>
                <feather-icon
                  :icon="icons.faqProjects"
                  size="18"
                  class="mr-1"
                />
                <span class="font-weight-bold">{{ $t("faq.Projects") }}</span>
              </template>

              <faq-question-answer :options="faqProjects" />
            </b-tab>
            <!--/ FAQ Projects tab -->

            <!-- FAQ Services tab -->
            <b-tab v-if="faqServices.length > 0">
              <!-- title -->
              <template #title>
                <feather-icon
                  :icon="icons.faqServices"
                  size="18"
                  class="mr-1"
                />
                <span class="font-weight-bold">{{ $t("faq.Services") }}</span>
              </template>

              <faq-question-answer :options="faqServices" />
            </b-tab>
            <!--/ FAQ Services tab -->

            <!-- FAQ Products tab -->
            <b-tab v-if="faqProducts.length > 0">
              <!-- title -->
              <template #title>
                <feather-icon
                  :icon="icons.faqProducts"
                  size="18"
                  class="mr-1"
                />
                <span class="font-weight-bold">{{ $t("faq.Products") }}</span>
              </template>

              <faq-question-answer :options="faqProducts" />
            </b-tab>
            <!--/ FAQ Products tab -->

            <!-- sitting lady image -->
            <template #tabs-end>
              <b-img
                fluid
                :src="
                  require('@/assets/images/illustration/faq-illustrations.svg')
                "
                class="d-none d-md-block"
              />
            </template>
            <!--/ sitting lady image -->
          </b-tabs>
        </section>
        <!--/ frequently asked questions tabs pills -->

        <!-- contact us -->
        <section class="faq-contact">
          <b-row class="mt-5 pt-75 text-center">
            <b-col cols="12">
              <h2>{{ $t("faq.still_have_question") }}</h2>
              <b-card-text class="mb-3">
                {{ $t("faq.cannot_find_question") }}
              </b-card-text>
            </b-col>
            <b-col sm="6">
              <b-card class="shadow-none py-1 faq-contact-card">
                <img
                  src="../../../assets/images/info/phone.png"
                  alt=""
                  width="60px"
                />
                <!-- <b-avatar
                  size="42"
                  rounded
                  variant="light-primary"
                  class="mb-2"
                >
                  <feather-icon icon="PhoneCallIcon" size="18" />
                </b-avatar> -->
                <h4>{{ $t("faq.phone") }}</h4>
                <span class="text-body">{{ $t("faq.We_always_happy") }}</span>
              </b-card>
            </b-col>
            <b-col sm="6">
              <b-card class="shadow-none py-1 faq-contact-card">
                <img
                  src="../../../assets/images/info/email.png"
                  alt=""
                  width="60px"
                />
                <!-- <b-avatar
                  size="42"
                  rounded
                  variant="light-primary"
                  class="mb-2"
                >
                  <feather-icon icon="MailIcon" size="18" />
                </b-avatar> -->
                <h4>{{ $t("faq.email") }}</h4>
                <span class="text-body">{{ $t("faq.get_answer_faster") }}</span>
              </b-card>
            </b-col>
          </b-row>
        </section>
        <!--/ contact us -->
      </b-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/layouts/landpage/HeaderHome.vue";

import Footer from "@/layouts/landpage/Footer.vue";
import {
  BContainer,
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
} from "bootstrap-vue";
import FaqQuestionAnswer from "./FaqQuestionAnswer.vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    Header,
    Footer,
    BContainer,
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer,
    ToastificationContent,
  },
  data() {
    return {
      faqSearchQuery: "",
      faqData: [],
      faqGeneral: [],
      faqCompanies: [],
      faqFreelancers: [],
      faqInvestors: [],
      faqBrokers: [],
      faqAdvisors: [],
      faqPartners: [],
      faqVerification: [],
      faqBusinessLoans: [],
      faqIdeas: [],
      faqProjects: [],
      faqServices: [],
      faqProducts: [],
      icons: {
        faqCompanies: "ServerIcon",
        faqFreelancers: "StarIcon",
        faqInvestors: "UsersIcon",
        faqBrokers: "TargetIcon",
        faqAdvisors: "KeyIcon",
        faqPartners: "ShieldIcon",
        faqVerification: "CheckCircleIcon",
        faqBusinessLoans: "DollarSignIcon",
        faqIdeas: "TwitchIcon",
        faqProjects: "MessageCircleIcon",
        faqServices: "TrelloIcon",
        faqProducts: "ShoppingBagIcon",
      },
    };
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
  methods: {
    fetchData() {
      // this.$http
      //   .get("/faq/data", { params: { q: this.faqSearchQuery } })
      //   .then((res) => {
      //     this.faqData = res.data;
      //   });

      /*    axios.get("posts/allFaqs/").then((response) => {
        this.faqData = response.data.data;
      });*/

      // try {
      const formData = new FormData();
      formData.append("q", this.faqSearchQuery);
      // formData.append("page", currentPage.value);
      formData.append("perPage", 12);
      formData.append("type", "faq");
      formData.append("language", localStorage.getItem("language"));
      formData.append("belongFor", "general");

      axios.post("posts/search/", formData).then((res) => {
        this.faqGeneral = res.data.data;
      });
      // const { data } = response;
      // this.faqData = response.data;

      // totalFaqs.value = data.count;
      // return data.data;

      // } catch (error) {
      //   this.$toast({
      //     component: ToastificationContent,
      //     position: "top-right",
      //     props: {
      //       title:
      //         error.response.status === 401
      //           ? "you dont have permissions to do this action"
      //           : "Error fetching faqs list",
      //       icon: "AlertTriangleIcon",
      //       variant: "danger",
      //     },
      //   });
      // }
    },

    fetchData2() {
      // try {
      const formData = new FormData();
      formData.append("q", this.faqSearchQuery);
      // formData.append("page", currentPage.value);
      // formData.append("perPage", perPage.value);
      formData.append("perPage", 200);

      formData.append("type", "faq");
      formData.append("language", localStorage.getItem("language"));

      axios.post("posts/search/", formData).then((res) => {
        this.faqData = res.data.data;

        this.faqData.forEach((obj, index) => {
          if (obj.belongFor == "companies") {
            this.faqCompanies.push(obj);
          } else if (obj.belongFor == "freelancers") {
            this.faqFreelancers.push(obj);
          } else if (obj.belongFor == "investors") {
            this.faqInvestors.push(obj);
          } else if (obj.belongFor == "brokers") {
            this.faqBrokers.push(obj);
          } else if (obj.belongFor == "partners") {
          } else if (obj.belongFor == "advisors") {
            this.faqAdvisors.push(obj);
          } else if (obj.belongFor == "partners") {
            this.faqPartners.push(obj);
          } else if (obj.belongFor == "verification") {
            this.faqVerification.push(obj);
          } else if (obj.belongFor == "businessLoans") {
            this.faqBusinessLoans.push(obj);
          } else if (obj.belongFor == "ideas") {
            this.faqIdeas.push(obj);
          } else if (obj.belongFor == "projects") {
            this.faqprojects.push(obj);
          } else if (obj.belongFor == "services") {
            this.faqServices.push(obj);
          } else if (obj.belongFor == "products") {
            this.faqProducts.push(obj);
          }
        });
      });

      // } catch (error) {
      // }
    },
  },
  mounted() {
    this.fetchData2();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
<style>
.faq-top {
  margin-bottom: 75px;
}
/* .card.faq-search {
  background-repeat: no-repeat;
  background-position: center;
} */

.faq-top {
  background-image: url(../../../assets/images/info/faq.png);
  padding: 140px 0 60px 0;
  min-height: 30vh;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.breadcrumbs:before {
  content: "";
  background-color: rgba(27, 47, 69, 0.7);
  position: absolute;
  inset: 0;
}
.breadcrumbs h2 {
  font-weight: 500;
  color: #fff;
  font-family: var(--font-secondary);
}
.faq-search-input {
  width: 30%;
}

@media (max-width: 720px) {
  .faq-search-input {
    width: 60%;
  }
  .breadcrumbs h2 {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    font-family: var(--font-secondary);
  }
}
</style>
